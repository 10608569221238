// authContext.js
import { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { IUserProps } from '../common/data/userDummyData';
import { apiRequest } from '../common/other/apiRequest';

export interface IAuthContextProps {
    user: string;
    userType: number;
    setUser: (user: string) => void;
    setUserData: (data: Partial<IUserProps>) => void;
    setUserType: (type: number) => void;
    userData: Partial<IUserProps>;
    userId: number;
    loading: boolean;
}

const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
    children: ReactNode;
}

export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
    const [user, setUser] = useState<string>(localStorage.getItem('facit_authUsername') || '');
    const [userData, setUserData] = useState<Partial<IUserProps>>({});
    const [userId, setUserId] = useState<number>(0);
    const [userType, setUserType] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const userToken = localStorage.getItem('token');
        if (userToken) {
            const fetchUserData = async () => {
                try {
                    const response = await apiRequest({
                        url: '/user',
                        method: 'GET',
                    });
                    setUserData(response.data);
                    setUserId(response.data.id);
                    setUserType(response.data.type);
                } catch (error) {
                    localStorage.removeItem('token');
                    setUserData({});
                    console.error('Error fetching user data:', error);
                } finally {
                    setTimeout(() => setLoading(false), 500);
                }
            };
            fetchUserData();
        } else {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('facit_authUsername', user);
    }, [user]);

    const value = useMemo(
        () => ({
            user,
            userType,
            setUser,
            setUserData,
            setUserType,
            userData,
            userId,
            loading,
        }),
        [user, userType, userData, userId, loading],
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AuthContext;
