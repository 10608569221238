import React, { SVGProps } from 'react';

const SvgFilterList = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
			<path d="M358.3,448h9.7V329.9v-11.7c0-6.5-5.7-12.2-12.4-12.2H156.4c-6.7,0-12.4,5.6-12.4,12.2v11.7V448h9.7c0.9,0,1.8,0,2.7,0   h199.3C356.6,448,357.5,448,358.3,448z"/>
			<path d="M423.8,131H393V78.7c0-8.3-7.3-14.7-15.9-14.7H134.9c-8.6,0-15.9,6.4-15.9,14.7V131H88.2C60.3,131,32,158.4,32,185.6    v120.8c0,27.2,28.3,61.7,56.2,61.7c0,0,13.3,0,18.3,0c4.9,0,5.5,0,5.5-14v-43c0-18.8,15.2-32,32.9-32h224    c17.7,0,31.1,21.3,31.1,40v35c0,14,0.1,14,5,14c5,0,18.7,0,18.7,0c27.9,0,56.2-26.5,56.2-53.7V185.6    C480,158.4,451.7,131,423.8,131z M400,192c-8.8,0-16-7.2-16-16s7.2-16,16-16s16,7.2,16,16S408.8,192,400,192z"/>
		</svg>
	);
};

export default SvgFilterList;
