import React, { useLayoutEffect, forwardRef, ReactElement, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useNavigate, useLocation } from 'react-router-dom';
import { ISubHeaderProps } from '../SubHeader/SubHeader';
import { IPageProps } from '../Page/Page';
import AuthContext from '../../contexts/authContext';
import { authMenu } from '../../menu';

interface IPageWrapperProps {
	isProtected?: boolean;
	title?: string;
	description?: string;
	children:
		| ReactElement<ISubHeaderProps>[]
		| ReactElement<IPageProps>
		| ReactElement<IPageProps>[];
	className?: string;
}
const PageWrapper = forwardRef<HTMLDivElement, IPageWrapperProps>(
	({ isProtected, title, description, className, children }, ref) => {
		useLayoutEffect(() => {
			document.getElementsByTagName('TITLE')[0].textContent = `${title ? `${title} | ` : ''}${process.env.REACT_APP_SITE_NAME}`;
			document?.querySelector('meta[name="description"]')?.setAttribute('content', description || process.env.REACT_APP_META_DESC || '');
		}, [title, description]);

		const { userData } = useContext(AuthContext);
		const navigate = useNavigate();
		const location = useLocation();

		useEffect(() => {
			const userToken = localStorage.getItem('token');
			const paths = ['/forgot-password', '/reset-password', '/auto-login'];
		
			if (isProtected && !userToken && !paths.includes(location.pathname)) {
				navigate(authMenu.login.path);
			}
		}, [isProtected, navigate, location.pathname]);
		

		return (
			<div ref={ref} className={classNames('page-wrapper', 'container-fluid', className)}>
				{children}
			</div>
		);
	},
);
PageWrapper.displayName = 'PageWrapper';
PageWrapper.propTypes = {
	isProtected: PropTypes.bool,
	title: PropTypes.string,
	description: PropTypes.string,
	// @ts-ignore
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};
PageWrapper.defaultProps = {
	isProtected: true,
	title: undefined,
	description: undefined,
	className: undefined,
};

export default PageWrapper;
