import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';

import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import Spinner from '../../../components/bootstrap/Spinner';
import { apiRequest } from '../../../common/other/apiRequest';
import AuthContext from '../../../contexts/authContext';
import showNotification from '../../../components/extras/showNotification';

interface ILoginProps {
	isSignUp?: boolean;
}

const Login = () => {
	const { darkModeStatus } = useDarkMode();
	const params = new URLSearchParams(window.location.search);
	const email = params.get('email');
	const password = params.get('password');

	const [dataState, setDataState] = useState(false)
	const { setUser, userId } = useContext(AuthContext);
	const { setUserData } = useContext(AuthContext);
	const { setUserType } = useContext(AuthContext);
	const [loginSuccess, setLoginSuccess] = useState(false);
	const navigate = useNavigate();
	const handleOnClick = useCallback(() => navigate('/'), [navigate]);
	const handleLogin = async () => {
		const response = await apiRequest({
			url: '/auto-login',
			method: 'POST',
			body: {
				email,
				password,
			},
		});

		if (response.status === 200) {
			localStorage.setItem('token', response.data.token);
			localStorage.setItem('userId', response.data.userData.id);
			localStorage.setItem('ljs_asideStatus', 'true');
			if (setUser) {
				setUser('john');
				if (setUserData) {
					setUserData(response.data.userData);
				}
				if (setUserType) {
					setUserType(response.data.userData.type);
				}
			}
			const success = 'Başarılı';
			const successdet = 'success';
			//showNotification(response.data.message, success, successdet, 2000);
			setTimeout(() => {
				setLoginSuccess(true);
				handleOnClick();
			}, 2000);
		} else {
			const success = 'Başarısız';
			const successdet = 'danger';
			//showNotification(response.data.message, success, successdet, 2000);

			setTimeout(() => {
				setLoginSuccess(false);
			}, 2000);
		}
	}

	useEffect(() => {
		setLoginSuccess(true);

		if (userId > 0) {
			setDataState(true)
			setTimeout(() => {
				handleOnClick();
			}, 2000);
		} 
		else {
			if (email && password) {
				handleLogin();
				setDataState(true)
			} else {
				setDataState(false)
			}
		}

	}, []);

	return (
		<PageWrapper
			isProtected={false}
			className='bg-light'>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xxl-6 col-lg-8 col-md-10 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-1'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}
										aria-label='Facit'>
										<Logo height={200} />
									</Link>
								</div>
								<div className='text-center h1 fw-bold'>Hoşgeldiniz,</div>
								{dataState ? (<div className='text-center h4 text-muted mb-5'>
									{loginSuccess ? (<><span className='me-3'>Bilgiler doğrulanıyor...</span>
										<Spinner />
									</>) :
										(
											<span className='text-danger'>Bilgiler doğrulanamadı.</span>
										)}
								</div>)
									:
									(<div className='text-center h4 text-muted mb-5'>Bilgiler alınırken bir problem oluştu.</div>)
								}

							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
