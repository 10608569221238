import React from "react";

import Button from "../../../components/bootstrap/Button";
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../../components/bootstrap/Modal';
interface AgreementModalProps {
    isOpen: boolean;
    toggle: () => void;
}
const AgreementModal: React.FC<AgreementModalProps> = ({ isOpen, toggle }) => {
    return (
        <Modal isOpen={isOpen} setIsOpen={toggle} fullScreen>
            <ModalHeader>
                <h2>Lojistigo Kullanıcı Sözleşmesi</h2>
            </ModalHeader>
            <ModalBody style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}>
                <h5>KULLANICI SÖZLEŞMESİ</h5>
                <p>İş bu Kullanıcı Sözleşmesi (“Sözleşme”) bu web sitesinin (“Site”) ve burada sunulan yazılımların ve hizmetlerin (“Hizmet(ler)”) kullanımını düzenlemektedir.</p>
                <p>Sözleşme, Kullanıcının elektronik ortamda kabulü ile birlikte yürürlüğe girecek olup; taraflarca Sözleşme’de belirtilen usuller doğrultusunda sona erdirilmediği sürece yürürlükte kalmaya devam edecektir. İş bu Sözleşme, kullanıcının münferit abone, tek bir şirket veya kuruluş (“Kullanıcı(lar)”) olması farketmeksizin Kullanıcı ve Lojistigo arasında gerçekleşen yasal bir sözleşmedir.</p>
                <p>Kullanıcı, web sitemize üye olmakla birlikte, işbu sözleşme ile bağlı olduğunu kabul etmiş olup Kullanıcı Bilgilerinin, aşağıda verilen Lojistigo Gizlilik Sözleşmesi'nde belirtilen şekilde toplanmasını ve kullanılmasını da kabul etmiş sayılacaktır. Eğer kullanıcı; bir şirket, kuruluş veya tüzel kişi adına bir hesap açıyor veya kullanıyorsa: (i) iş bu sözleşmeyle bağlı olacak şekilde o kurumun yetkili temsilcisi olduğunu; (ii) iş bu sözleşmeyi okuduğunu; (iii) anladığını (iv) tüzel kişi adına onayladığını kabul ve taahhüt etmiş olacaktır.</p>
                <h6>1) Hizmetin Tanımı</h6>
                <p>İşbu Site ve burada sunulan Hizmetler Lojistigo Lojistik ve Yazılım Sistemleri Ticaret Limited Şti’ne(kısaca “Lojistigo”) ait olup, perakendecilikle uğraşanlar için sipariş karşılama sürecini kolaylaştıran web tabanlı bir nakliye çözümüdür. Popüler pazarlara ve alışveriş platformlarına gerçek zamanlı entegrasyonlarla, sipariş alma ve toplu etiket oluşturmadan müşteri iletişimine kadar her türlü faaliyeti gerçekleştirmektedir. Gelişmiş özelleştirme seçenekleri, Lojistigo’nun işletmelere istenilen sayıda kullanıcı veya konumla uyum sağlamasını destekler. Lojistigo’nun sadece yazılımını kullananlar “Abone Kullanıcılar”, yazılım dışında sunulan lojistik hizmetinden de faydalananlar ise “Özel Kullanıcılar” olarak adlandırılmaktadır. Her iki kullanıcı türünün tabi olduğu ücret ve koşullar birbirinden farklıdır.</p>
                <h6>2) Site ve Hizmete Dair Genel Bilgiler</h6>
                <p>2.1. Lojistigo, Site’deki bilgilerin doğruluğunu ve güncelliğini sürekli olarak kontrol eder. Ancak, gösterilen tüm çabalara rağmen ortaya çıkan yazım ve sistem hatalarından Lojistigo sorumlu değildir. Kullanıcı, Hizmet’e erişimin muhtelif zamanlarda engellenebileceğini ya da erişimin kesilebileceği kabul eder.</p>
                <p>2.2. Lojistigo Site’de yer alan veya alacak olan bilgileri, formları, içeriği ve iş bu Kullanıcı Sözleşmesi’ni dilediği zaman değiştirme hakkını saklı tutmaktadır.</p>
                <p>2.3 Lojistigo hesapları feshedebilir, Hizmete gönderilen tüm içeriği veya bilgileri silebilir ve / veya herhangi bir sebepten dolayı Hizmet’in (veya Hizmet’in herhangi bir kısmının veya özelliğinin) kullanımını veya erişimini herhangi bir zamanda, kendi takdirine bağlı olarak, bildirimde bulunarak veya bulunmayarak engelleyebilir. Lojistigo’nun herhangi bir sebepten ötürü Lojistigo'da yer alan herhangi bir hesabı (tamamen veya kısmen) feshetmesi kendi inisiyatifindedir. Ayrıca, Lojistigo, herhangi bir Kullanıcı verisinin görüntülenmesini durdurma hakkı da dahil olmak üzere Hizmet’in herhangi bir yönünü durdurma hakkını saklı tutar.</p>
                <p>2.4. Site diğer web sitelerine bağlantılar içerebilir ("Bağlantılı Siteler"). Bağlantılı Sitelerde, Lojistigo’nun herhangi bir kontrolü ve sorumluluğu bulunmamaktadır.</p>
                <p>2.5. Taşınması Türk veya ilgili ülke mevzuatlarına göre yasaklanmış bulunan ürünlerin gönderilmesi talepleri karşılanmayacak olup Lojistigo’nun söz konusu hallerde Siteye ve ilgili hizmetlere erişimi engelleme hakkı bulunmaktadır.</p>
                <p>2.6. Lojistigo, Kullanıcı tarafından kendisi ile paylaşılan bilgi ve verileri işbu Sözleşme’ye ek niteliğinde olan “Gizlilik Politikası” kapsamında saklayacak ve kullanacaktır. Kullanıcı, Lojistigo’nun yürürlükteki mevzuat uyarınca yetkili makamlardan talep gelmesi halinde Kullanıcı’nın kendisinde bulunan bilgilerini ilgili yetkili makamlarla paylaşabileceğini kabul eder.</p>
                <p>2.7. Lojistigo, kullanıcıyı bilgilendirmeden gerekli gördüğü sistem değişikliklerini, güncellemelerini ve kısıtlamalarını değiştirme hakkını saklı tutar.</p>
                <h6>3) Kullanıcının Hak ve Yükümlülükleri</h6>
                <p>3.1. Kullanıcı, Kullanıcı hesabını ve işbu Sözleşme ile Site kullanımından doğan hak ve yükümlülüklerini herhangi bir şekilde üçüncü bir kişiye devir veya temlik edemez. Lojistigo’nun sunduğu Hizmetler’den yararlananlar ve Site'yi kullananlar, yalnızca hukuka uygun ve şahsi/kurumsal amaçlarla Site üzerinden işlem yapabilirler. Kullanıcı'nın, Site kapsamında gerçekleştirdiği her işlem ve eylemdeki hukuki ve cezai sorumluluk kendisine aittir.</p>
                <p>3.2. Kullanıcı tarafından Site’ye erişim e-posta adresi ve şifresi kullanılarak gerçekleştirilecektir. Bu şifrenin gizliliğinin ve güvenliğinin korunmasından Kullanıcı sorumlu olacak olup Site üzerinden söz konusu bilgilerin kullanımı ile gerçekleştirilen her türlü faaliyetin Kullanıcı tarafından gerçekleştirildiği kabul edilecek ve aynı zamanda bu faaliyetlerden doğan her türlü yasal ve cezai sorumluluk Kullanıcı’ya ait olacaktır. Kullanıcı, şifresinin yetkisiz kullanımı veya güvenliğin başka şekilde ihlalinden haberdar olduğunda bu durumu derhal Lojistigo’ya bildirecektir.</p>
                <p>3.3. Kullanıcı, sadece tanıtım, reklam, kampanya, promosyon, duyuru vb. pazarlama faaliyetleri kapsamında kullanılması ile sınırlı olmak üzere, Lojistigo’nun, kendisine ait iletişim, portföy durumu ve demografik bilgilerini iştirakleri ya da bağlı bulunduğu şirketler ile paylaşmasına muvafakat ettiğini kabul ve beyan eder. Bu kişisel bilgiler Lojistigo bünyesinde müşteri profili belirlemek, müşteri profiline uygun promosyon ve kampanyalar sunmak ve istatistiksel çalışmalar yapmak amacıyla kullanılabilecektir. Kullanıcı, içerik ve diğer bilgilerin Lojistigo veya üçüncü kişiler tarafından Türkiye’de veya yurt dışında bulunan veri merkezlerinde saklanabileceğini kabul eder.</p>
                <h6>4) Fikri Mülkiyet Hakları</h6>
                <p>4.1. Kullanıcı, Site’de ve verilen Hizmet’te tüm fikri mülkiyet haklarının Lojistigo’ya ait olduğunu kabul ve beyan eder.</p>
                <p>4.2. İşbu Kullanıcı Sözleşmesi dahilinde Lojistigo tarafından sarahaten izin verilen durumlar haricinde, Kullanıcılar, Lojistigo’nun telif haklarına tabi çalışmalarını çoğaltamaz, işleyemez, dağıtamaz veya bunlardan türemiş çalışmalar yapamaz veya hazırlayamaz.</p>
                <h6>5) Mücbir Sebepler</h6>
                <p>Lojistigo, hukuken mücbir sebep sayılan tüm durumlarda, işbu sözleşmeden ve gizlilik politikasından doğan yükümlülüklerinden herhangi birini tam olarak ve zamanında ifa edememekten dolayı sorumlu değildir. Böyle bir hususun varlığı halinde Lojistigo’nun herhangi bir tazminat sorumluluğu doğmayacaktır.</p>
                <h6>6) Ücretler ve Vergiler</h6>
                <p>Aşağıda belirtilen ücretler Lojistigo’ya abone olan (sadece yazılımı kullanan) kullanıcılar için geçerli olacak olup, Lojistigo özel kullanıcıları (Lojistigo’nun hem yazılım hem kargo hizmetinden faydalananlar) için farklı ücretler belirlemek hakkını haizdir. Bunların dışında kullanıcıların özel kullanıcı ve abone kullanıcı olarak ayrı kategorilerde değerlendirilmesi tamamen Lojistigo’nun kendi inisiyatifinde olup zaman içinde bir kullanıcının özelden aboneye, aboneden özele şeklinde sahip olduğu kullanıcı statüsünün değiştirilmesi de yine Lojistigo’nun tasarrufundadır.</p>
                <p>6.1 Hizmet ile ilgili ödeme ve vergi gibi ücretlerin geçerli bir ödeme yöntemiyle ve zamanında ödenmesinden Kullanıcı sorumludur. Aksi belirtilmedikçe, tüm ücretler ABD Doları cinsindendir. Tüm ödemeler Hizmet içerisinde belirtilen yöntemlerle yapılmalıdır. Tüm ücretler ve masraflar tarafımızdan alındıktan sonra kazanılır ve (a) açıkça belirtildiği durumlarda ve / veya (b) yürürlükteki yasaların gerektirdiği haller dışında, iade edilemez.</p>
                <p>6.2 Kullanıcı satış, kullanım veya katma değer vergisi gibi Hizmet'i kullanmasına dair öngörülen herhangi bir devlet vergisini ödemekle yükümlüdür. Lojistigo’nun bu tür vergileri tahsil etmek zorunda olduğu ölçüde, geçerli vergi, faturalandırma hesabına eklenecektir.</p>
                <p>6.3 Seçilen ödeme yöntemi hesabını tahsil etme yetkisi, Hizmet içindeki tercihler iptal edinceye veya değiştirilinceye dek yürürlükte kalacaktır; Ancak, böyle bir bildirim, Lojistigo’nun makul bir şekilde harekete geçmesinden önce sunulan ücretleri etkilemeyecektir.</p>
                <p>6.4 Lojistigo, Hizmet için herhangi bir ücret veya ücret sınırını veya miktarını değiştirme ve Kullanıcılarına önceden bildirimde bulunarak yeni ücret veya şartlar oluşturma hakkını saklı tutar. Herhangi bir abonelik süresi için ödenen ücretler peşin olarak ödenir ve tamamen veya kısmen iade edilmez.</p>
                <p>6.5 Hizmet iptal edilmediği müddetçe aylık abonelikler her ayın sonunda, yıllık abonelikler ise her yıl dönümü bitiminde otomatik olarak yenilenecektir.</p>
                <p>6.6 Yıllık abonelikler için, yıllık yenileme tarihinden en az 30 gün önce, aboneliğin iptal edilmek istendiği bildirilmelidir. Aylık abonelikler için ise yedi (7) gün öncesinde bildirim yapmak gerekmektedir.</p>
                <h6>7) Sözleşmenin Sonlandırılması</h6>
                <p>7.1. Taraflardan herhangi biri, diğer tarafça bildirilen elektronik posta adresine 1 (bir) hafta önceden yapacağı yazılı bir bildirimle işbu Sözleşme’yi dilediği zaman herhangi bir gerekçe göstermeksizin ve tazminat ödemeksizin feshedebilecektir.</p>
                <p>7.2. İşbu Sözleşme’nin herhangi bir hükmünün veya sözleşmede yer alan herhangi bir ifadenin geçersizliği, yasaya aykırılığı ve uygulanamazlığı, Sözleşme’nin geri kalan hükümlerinin yürürlüğünü ve geçerliliğini etkilemeyecektir.</p>
                <p>7.3. İşbu Sözleşme’nin uygulanmasından veya yorumlanmasından doğacak her türlü uyuşmazlığın çözümünde İstanbul Çağlayan Adliyesi Mahkemeleri ve İcra Daireleri yetkilidir.</p>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Kapat
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default AgreementModal;
