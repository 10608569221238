import { RouteProps } from 'react-router-dom';
import {
	authMenu,
	landingPagesMenu,
} from '../menu';

import DefaultHeader from '../pages/_layout/_headers/DefaultHeader';


const headers: RouteProps[] = [
	{ path: authMenu.login.path, element: null },
	{ path: authMenu.signUp.path, element: null },
	{ path: authMenu.forgotPassword.path, element: null },
	{ path: authMenu.page404.path, element: null },
	{ path: authMenu.autoLogin.path, element: null },
	{ path: landingPagesMenu.downloadBarcode.path, element: null },
	{ path: landingPagesMenu.invoice.path, element: null },
	{ path: authMenu.resetPassword.path, element: null },
	{ path: landingPagesMenu.barcode.path, element: null },
	{
		path: `*`,
		element: <DefaultHeader />,
	},
];

export default headers;
