import React, { Suspense } from 'react';
import PageWrapper from '../PageWrapper/PageWrapper';
import Page from '../Page/Page';
import SubHeader from '../SubHeader/SubHeader';
import ContentRoutes from './ContentRoutes';
import Card, { CardBody } from '../../components/bootstrap/Card';
import { CircularProgress } from '@mui/material';

const LOADING = (
    <>
        <CircularProgress />
    </>
);

const Content = () => {
    return (
        <main className='content'>
            <Suspense fallback={LOADING}>
                <ContentRoutes />
            </Suspense>
        </main>
    );
};

export default Content;
