import React from 'react';
import { useTour } from '@reactour/tour';
import { createUseStyles } from 'react-jss';
import SusyDarkMode from './assets/img/wanna/susy/susy-dark-mode.png';
import Susy from './assets/img/wanna/susy/susy.png';
import Susy2 from './assets/img/wanna/susy/susy2.png';
import Susy7 from './assets/img/wanna/susy/susy7.png';
import Button from './components/bootstrap/Button';

/**
 * Steps style
 */
export const styles = {
	maskWrapper: (base: any) => ({ ...base }),
	maskArea: (base: any) => ({
		...base,
		rx: 'var(--bs-border-radius)',
	}),
	highlightedArea: (base: any) => ({ ...base }),
	badge: (base: any) => ({ ...base }),
	popover: (base: any) => ({
		...base,
		boxShadow: '0 0 3em rgba(0, 0, 0, 0.5)',
		backgroundColor: 'var(--bs-body-bg)',
		color: 'var(--bs-body-color)',
		borderRadius: 'var(--bs-border-radius)',
	}),
};

/**
 * Image Styles
 * @type {(data?: {theme?: DefaultTheme}) => Classes<"image">}
 */
const useStyles = createUseStyles({
	image: {
		maxHeight: '150px',
		objectFit: 'contain',
	},
});

/**
 * Prev & Next buttons
 * @returns {JSX.Element}
 * @constructor
 */
const TourNavigation = () => {
	const { currentStep, setCurrentStep } = useTour();
	return (
		<div className='col-12 mt-3'>
			<div className='row'>
				<div className='col'>
					{!!currentStep && (
						<Button
							icon='ArrowBackIos'
							color='info'
							isLink
							onClick={() => setCurrentStep(currentStep - 1)}>
							Önceki
						</Button>
					)}
				</div>
				<div className='col-auto'>
					<Button
						icon='ArrowForwardIos'
						color='info'
						isLight
						onClick={() => setCurrentStep(currentStep + 1)}>
						Sonraki
					</Button>
				</div>
			</div>
		</div>
	);
};

interface PageTourProps {
	imageSrc: string;
	title: string;
	description: string;
}

/**
 * Selector
 * @param name
 * @returns {`[data-tour='${string}']`}
 */
const getTargetName = (name: string): `[data-tour='${string}']` => `[data-tour='${name}']`;

const PageTour: React.FC<PageTourProps> = ({ imageSrc, title, description }) => {
	const classes = useStyles();
	return (
		<div className='row'>
			<div className='col-md-4'>
				<img src={imageSrc} className={classes.image} width='100%' alt='' />
			</div>
			<div className='col-md-8 d-flex align-items-center'>
				<div>
					<p className='lead'>{title}</p>
					<p>{description}</p>
				</div>
			</div>
			<TourNavigation />
		</div>
	);
};

/**
 * Tour Steps
 */
const steps = [
	{
		selector: getTargetName('dark-mode'),
		content: () => <PageTour imageSrc={SusyDarkMode} title="Dark / Light Mod" description="Dark ve Light modlar arasında geçiş yapabilirsiniz." />,
	},
	{
		selector: getTargetName('price-calculation'),
		content: () => <PageTour imageSrc={Susy} title="Fiyat Hesaplama" description="Kargolarınız için kolayca fiyat hesaplayabilirsiniz." />,
	},
	{
		selector: '[href="/"]',
		content: () => <PageTour imageSrc={Susy2} title="Kontrol Paneli" description="Kontrol panelinizi buradan görüntüleyebilirsiniz." />,
	},
	{
		selector: '[href="/create-order"]',
		content: () => <PageTour imageSrc={Susy7} title="Sipariş Oluştur" description="Yeni sipariş oluşturmak için buraya tıklayabilirsiniz." />,
	},
	{
		selector: '[href="/price-defination"]',
		content: () => <PageTour imageSrc={Susy7} title="Fiyat Tanımlama" description="Fiyat tanımlama işlemlerini buradan yapabilirsiniz." />,
	},
	{
		selector: '[href="/carrier-defination"]',
		content: () => <PageTour imageSrc={Susy7} title="Taşıyıcı Listesi" description="Taşıyıcı listesi işlemlerini buradan yapabilirsiniz." />,
	},
	{
		selector: '[href="/country-zones"]',
		content: () => <PageTour imageSrc={Susy7} title="Ülke Bölge Tanımlama" description="Ülke ve bölge tanımlama işlemlerini buradan yapabilirsiniz." />,
	},
	{
		selector: '[href="/users"]',
		content: () => <PageTour imageSrc={Susy7} title="Kullanıcı Listesi" description="Kullanıcı listesine buradan erişebilirsiniz." />,
	},
	{
		selector: '[href="/announcements"]',
		content: () => <PageTour imageSrc={Susy7} title="Duyurular" description="Duyurulara buradan erişebilirsiniz." />,
	},
	{
		selector: '[href="/accept-shipments"]',
		content: () => <PageTour imageSrc={Susy7} title="Gönderi Kabul" description="Gönderi kabul işlemlerini buradan yapabilirsiniz." />,
	},
	{
		selector: '#user-pages__listPages--link',
		content: () => <PageTour imageSrc={Susy7} title="Gönderiler" description="Gönderilerinizi buradan görebilirsiniz." />,
	},
	{
		selector: '[href="/calculate-price"]',
		content: () => <PageTour imageSrc={Susy7} title="Fiyat Hesapla" description="Fiyat hesaplama işlemlerini buradan yapabilirsiniz." />,
	},
	{
		selector: '[href="/balance/add"]',
		content: () => <PageTour imageSrc={Susy7} title="Para Ekle" description="Bakiyenizi buradan ekleyebilirsiniz." />,
	},
	{
		selector: '[href="/support"]',
		content: () => <PageTour imageSrc={Susy7} title="Destek" description="Destek almak için buradan iletişime geçebilirsiniz." />,
	},
	{
		selector: '[href="/etgb-forms"]',
		content: () => <PageTour imageSrc={Susy7} title="Etgb Formları" description="Etgb formlarınızı buradan yönetebilirsiniz." />,
	},
	{
		selector: '[href="/integrations"]',
		content: () => <PageTour imageSrc={Susy7} title="Entegrasyonlar" description="Entegrasyonlarınız buradan yönetebilirsiniz." />,
	},
];

export default steps;
