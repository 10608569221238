// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/react-in-jsx-scope */
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Brand from '../../../layout/Brand/Brand';
import Navigation, { NavigationLine, NavigationTitle } from '../../../layout/Navigation/Navigation';
import User from '../../../layout/User/User';
import {
	createOrderMenu,
	dashboardPagesMenu,
	userPagesMenu,
	adminPagesMenu,
	moderatorPagesMenu,
} from '../../../menu';
import ThemeContext from '../../../contexts/themeContext';
import useDarkMode from '../../../hooks/useDarkMode';
import Aside, { AsideBody, AsideFoot, AsideHead } from '../../../layout/Aside/Aside';
import useAuth from '../../../hooks/useAuth';
import { moderateRoutes } from '../../../routes/contentRoutes';
import { BorderColor } from '../../../components/icon/material-icons';

const DefaultAside = () => {
	const { userData } = useAuth();
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);

	const [doc, setDoc] = useState(
		localStorage.getItem('facit_asideDocStatus') === 'true' || false,
	);

	const { t } = useTranslation(['translation', 'menu']);

	const { darkModeStatus } = useDarkMode();

	return (
		<>
		{Object.keys(userData).length <= 0 ? <></> : 
		<Aside>
			<AsideHead>
				<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideHead>
			<AsideBody>
				{!doc && (
					<>
						<Navigation menu={dashboardPagesMenu} id="aside-dashboard" />
						<Navigation menu={createOrderMenu} id="aside-createOrder" />
						{userData?.user_group_id == 3 && (<>
							<NavigationTitle style={{ color: 'black' }}>Kullanıcı</NavigationTitle>
							<Navigation menu={userPagesMenu} id="user-pages" />
						</>
						)}
						{userData?.user_group_id == 2 && (<>
							<NavigationTitle style={{ color: 'black' }}>Moderatör</NavigationTitle>
							<Navigation menu={moderatorPagesMenu} id="moderator-pages" />
							<NavigationTitle style={{ color: 'black' }}>Kullanıcı</NavigationTitle>
							<Navigation menu={userPagesMenu} id="user-pages" />
						</>
						)}
						{userData?.user_group_id == 1 && (
							<>
								<NavigationTitle style={{ color: 'black' }}>Admin</NavigationTitle>
								<Navigation menu={adminPagesMenu} id="admin-pages" />
								<NavigationTitle style={{ color: 'black' }}>Moderatör</NavigationTitle>
								<Navigation menu={moderatorPagesMenu} id="moderator-pages" />
								<NavigationTitle style={{ color: 'black' }}>Kullanıcı</NavigationTitle>
								<Navigation menu={userPagesMenu} id="user-pages" />
							</>
						)}
					</>
				)}
			</AsideBody>
			<AsideFoot>
				<User />
			</AsideFoot>
		</Aside>
		}
		</>
	);
};

export default DefaultAside;
