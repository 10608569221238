import React from "react";

import Button from "../../../components/bootstrap/Button";
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../../components/bootstrap/Modal';
interface AgreementModalProps {
    isOpen: boolean;
    toggle: () => void;
}
const KvkkModal: React.FC<AgreementModalProps> = ({ isOpen, toggle }) => {
    return (
        <Modal isOpen={isOpen} setIsOpen={toggle} fullScreen>
            <ModalHeader>
                <h2>KVKK Metni</h2>
            </ModalHeader>
            <ModalBody style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}>
    <h5>Lojistigo Lojistik ve Yazılım Ticaret Limited Şirketi</h5>
    <p><strong>Kişisel Verilerin Korunmasına İlişkin Aydınlatma Metni</strong></p>
    
    <h6>1) 6698 Sayılı Kişisel Verilerin Korunması Kanunu (KVKK)</h6>
    <p>Şirketimiz Lojistigo Lojistik ve Yazılım Ticaret Limited Şirketi Veri Sorumlusu sıfatıyla, kişisel verileriniz, iş amaçlarıyla bağlı ve sınırlı olmak üzere aşağıda açıklanan metin uyarınca kaydedilmek, saklanmak, aktarılmak, güncellenmek ve sınıflandırmak amacıyla işlenecektir. Bu kapsamda Şirketimiz tarafından başta özel hayatın gizliliği olmak üzere, kişilerin temel hak ve özgürlüklerini korumak ve kişisel verilerin korunması amacıyla düzenlenen Kanun ve Yönetmelikler gereği, kişisel verilerinizin hukuka aykırı biçimde işlenmesini erişilmesini önleme ve korumasını sağlama amacıyla üst düzey teknoloji ile kişisel verilerinizin güvenliği sağlanmaktadır.</p>
    
    <h6>2) Şirketimiz tarafından işlenen kişisel verileriniz şunlardır:</h6>
    <p>İsim-Soyisim, Kimlik numarası, Telefon numaraları (Ev-İş), E-posta adresi, imza, çağrı merkezi/müşteri hizmetleri ses kayıtları, banka bilgileri, iletişim adresleri (alım-gönderim adresleri), alıcılara ait adres ve iletişim bilgileri, alıcılara ait kimlik bilgileri, teslimat ürününe ilişkin detaylı bilgiler tarafımıza iletmiş olduğunuz kişisel veriler, arayan kişiye doğru hitapta bulunabilmek, şirketimizce satış, tedarik, lojistik yönetimi hususlarında kontrol sağlamak, süreci yönetmek, faturalandırma yapmak, yasal bildirimlerde bulunmak, teslimat işlemlerini gerçekleştirmek, müşteri talep ve/veya şikayetlerinin takibini yapmak, iş süreçlerinin yönetilmesini sağlamak, her türlü ürün ve hizmet tanıtımında bulunmak, reklam, iletişim, promosyon, pazarlama, kullanım alışkanlıklarının belirlenmesini sağlamak, ticari elektronik ileti gönderilebilmesi, müşteriyi ürün ve hizmetlerden faydalandırmak, ürün ve hizmetlerin satış ve pazarlama faaliyetlerinin planlanması, sözleşme ve yasadan doğan mesuliyetlerin eksiksiz ve doğru bir şekilde yerine getirilmesi, üyelere özel hizmetlerin oluşturulması, ziyaretçi ve müşteri kayıtlarının oluşturulması, ticari işbirliklerinin (taşımacı, acente, tedarikçi vs.) talep edilen hizmete ilişkin süreçleri tamamlaması, ticari işbirliklerinin yönetim ve iletişim faaliyetlerinin ve stratejilerinin doğru olarak planlanması, yürütülmesi, yönetilmesi, internet sitemizde sunulan hizmetlerin geliştirilmesi ve sitede oluşan hataların giderilmesi, sunulan ürün ve hizmete ilişkin sözleşme ve mali mutabakatın sağlanabilmesi, doğabilecek uyuşmazlıklarda delil olabilmesi amaçlarıyla işlenebilir.</p>

    <h6>3) Kişisel Verilerinizin Aktarımı</h6>
    <p>Kişisel Verileriniz, Kanunlar ve Yönetmelikler kapsamında ve yukarıda belirtilen amaçlar doğrultusunda; Şirket hissedarlarına, çalışanlarına, Şirketimiz ile işbirliğinde bulunan iş ortaklarımıza, tedarikçilerimize, acente ve şubelerimize, taşımacılarımıza, yüklenicilerimize, yurtiçi/yurtdışı iştiraklerimize ve işbirliği çerçevesinde hizmetin tamamlanmasını sağladığımız şirketlere, ,kişilere, kuruluşlara, verileri alma yetkisi bulunan kamu tüzel kişilerine, bankalar ve kredi risk ve finans kuruluşlarına ve sair gerçek ve tüzel kişilere, Vergi, hukuk ve benzeri danışmanlara, yasal takip süreçleri ile ilgili zorunlu kişilere, kurum ve kuruluşlara ve denetimciler de dahil olmak üzere Şirketimizin danışmanlık hizmeti aldığı üçüncü kişilere, yetki vermiş olduğumuz Şirketimiz nam ve hesabına faaliyette bulunan şirketlere, temsilcilerimize, düzenleyici ve denetleyici kurumlara, kişisel verilerinizi tabi olduğu kanunlarda açıkça talep etmeye yetkili olan kişi, kurum ve kuruluşlara işbu metnin 3. Maddesinde yer alan amaçlarla aktarılabilecektir.</p>
    
    <h6>4) Kişisel Verilerinizin Toplanması ve Saklanması</h6>
    <p>Şirketimiz ile paylaştığınız kişisel verileriniz, Şirket tarafından çalışanları, yurtiçi/yurtdışı iştirakleri, iş birliği yaptığı kişi, kurum ve/veya kuruluşlar, resmî kurumlar veya diğer üçüncü kişiler, tarafından otomatik ve/veya manuel şekilde internet sitemiz, çağrı merkezimiz, ofislerimiz, sosyal medya alanlarımız, mobil uygulamamız üzerinden sözlü veya yazılı şekilde işbu metnin 3. Maddesinde yer alan amaçlarla ve ilgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla veri sorumlusunun meşru menfaati için zorunlu olması nedeniyle toplanmaktadır. Kişisel Verileriniz fiziksel ve/veya elektronik ortamda saklanmakta ve korunmaktadır. Şirketimizde bulunan Kişisel Verilerinizin yetkisiz erişimine, kaybolmalarına, zarar görmelerine ve ele geçirilmelerine karşın Şirketimiz üst düzey teknolojik yöntemler ile güvenlik altyapısı oluşturmuş olup bu altyapı sürekli güncellenecektir. Kişisel verileriniz, tarafınızın onayı olmaksızın yukarıda belirtilen amaçlar dışında kullanılmamak kaydı ile gerekli tüm tedbirler alınmak suretiyle saklanacaktır. Bu saklama süresi yasal saklama süresi olup şayet yasal saklama süresi belli değil ise Kişisel Verilerinizin toplanma ve saklanma amacının gerekli kıldığı süre boyunca işlenip saklanacaktır. Bu sürenin sona ermesiyle birlikte Kişisel Verileriniz silinerek, yok edilerek veya anonimleştirilerek Şirketimiz veri tabanlarından çıkarılacaktır.</p>
    
    <h6>5) Kişisel Verilerinizle İlgili Haklarınız</h6>
    <p>Tarafınızca iletilen kişisel verileriniz doğrultusunda; a) kendisine ait kişisel verilerin işlenip işlenmediğini öğrenme, b) kişisel verileri işlenmişse buna ilişkin bilgi talep etme, c) kişisel verilerinin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme, d) yurt içinde veya yurt dışında kişisel verilerinin aktarıldığı üçüncü kişileri bilme, e) kişisel verilerinin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme, f) mevzuatta öngörülen şartlar çerçevesinde kişisel verilerinin silinmesini veya yok edilmesini isteme, g) (e) ve (f) bentleri uyarınca yapılan işlemlerin, kişisel verilerinin aktarıldığı üçüncü kişilere bildirilmesini isteme, h) işlenen verilerinin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme, ve i) kişisel verilerinin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme haklarınız bulunmaktadır. Belirtilen haklara ilişkin talepleriniz, www.lojistigo.com internet adresimizdeki başvuru formunun doldurularak Bağcılar – İstanbul adresine ulaştırılmanız ve info@lojistigo.com e-posta adresimize iletilmenizi takiben en geç 30 gün içerisinde ücretsiz sonuçlandırılacaktır.</p>
</ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Kapat
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default KvkkModal;
